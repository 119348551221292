<template>
  <section class="pa-0">
    <div class="d-flex flex-column">
      <div>
        <p class="mb-0 font-weight-bold">Fecha y hora de contratación</p>
        <p>
          {{
            moment(
              seguimientoContrato?.contrato?.fecha_hora_contratacion
            ).format("DD/MM/YYYY hh:mm")
          }}
        </p>
      </div>

      <div>
        <p class="mb-0 font-weight-bold">Nombre del proceso de compra</p>
        <p>{{ seguimientoContrato?.contrato?.proceso_compra?.nombre_proceso ?? '-' }}</p>
      </div>

      <div>
        <p class="mb-0 font-weight-bold">Código del proceso de compra</p>
        <p>{{ seguimientoContrato?.contrato?.numero }}</p>
      </div>

      <div>
        <p class="mb-0 font-weight-bold">Número de orden de compra</p>
        <p>{{ seguimientoContrato?.contrato?.numero }}</p>
      </div>
      <div>
        <p class="mb-0 font-weight-bold">Número interno de orden de compra</p>
        <p>{{ seguimientoContrato?.contrato?.numero_interno }}</p>
      </div>
      <!-- <div>
        <span class="mb-0 font-weight-bold" v-text="'Plazo contractual'" />
        <v-btn class="px-2" icon @click="openModalPlazo" v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')">
          <v-icon v-text="'mdi-pencil'" />
        </v-btn>
        <p v-text="seguimientoContrato?.contrato?.plazo_contractual" />
      </div> -->
      <div>
        <p class="mb-0 font-weight-bold">Monto Adjudicado</p>
        <p>$ {{ seguimientoContrato?.contrato?.monto_adjudicado }}</p>
      </div>
      <div v-if="seguimientoContrato?.contrato?.comentario">
        <p class="mb-0 font-weight-bold">Comentario sobre la Modificativa/Prórroga</p>
        <p> {{ seguimientoContrato?.contrato?.comentario }}</p>
      </div>
    </div>
    <!-- modal de modificación de plazo -->
    <v-dialog v-model="showModalPlazo" persistent max-width="700">
      <v-card class="pa-4" max-width="700">
        <div class="headerIcon">
          <v-icon color="secondary" @click="closeModalPlazo">
            mdi-close
          </v-icon>
        </div>
        <v-row>
          <v-col cols="12">
            <p
              class="text-h5 text-center secondary--text px-4"
              v-text="'Modificación de plazo contractual'"
            />
          </v-col>
        </v-row>
        <v-card-text>
          <v-row class="px-md-8">
            <v-col cols="12" md="6">
              <v-text-field
                label="Plazo actual"
                outlined
                :value="seguimientoContrato.contrato.plazo_contractual"
                readonly
                suffix="días"
                hide-details
              />
            </v-col>
            <v-col cols="0" md="6" />
            <v-col cols="12" md="6">
              <v-text-field
                :error-messages="addDayError"
                @blur="$v.add_days.$touch()"
                @input="$v.add_days.$touch()"
                clearable
                label="Días a agregar *"
                maxLength="4"
                outlined
                suffix="días"
                v-mask="'####'"
                v-model="add_days"
              />
              <span
                class="secondary--text"
                v-text="'Nuevo plazo: ' + newPlazo + ' días'"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-file-input
                :error-messages="docPlazoError"
                @blur="$v.doc_plazo.$touch()"
                @input="$v.doc_plazo.$touch()"
                accept="application/pdf"
                clearable
                label="Documento de plazo *"
                outlined
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                v-model="doc_plazo"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            outlined
            color="primary darken-1"
            class="text-capitalize mx-8"
            @click="closeModalPlazo"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="secondary"
            class="white--text mx-8 text-capitalize"
            @click="saveNewPlazo"
          >
            Agregar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import { mapState, mapActions } from "vuex";

// Validaciones
function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "generalComponent",
  validations: {
    add_days: {
      required,
      numeric,
    },
    doc_plazo: {
      required,
      fileNameValid,
      fileSizeValid: (value) => LegacyValidations.maxSizeRuleSimple(4294967296, value), // Valor en bites este es equivalente a 500 MB
    },
  },
  data: () => ({
    showModalPlazo: false,
    add_days: null,
    doc_plazo: null,
  }),
  computed: {
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
    newPlazo() {
      if (this.add_days == null) {
        return this.seguimientoContrato.contrato.plazo_contractual;
      } else {
        return (
          Number(this.seguimientoContrato.contrato.plazo_contractual) +
          Number(this.add_days)
        );
      }
    },
    addDayError() {
      const errors = [];
      if (!this.$v.add_days.$dirty) return errors;
      !this.$v.add_days.required && errors.push("Campo requerido");
      !this.$v.add_days.numeric && errors.push("Solo números");
      return errors;
    },
    docPlazoError() {
      const errors = [];
      if (!this.$v.doc_plazo.$dirty) return errors;
      !this.$v.doc_plazo.required && errors.push("Campo requerido");
      !this.$v.doc_plazo.fileNameValid && errors.push("Solo archivos PDF");
      !this.$v.doc_plazo.fileSizeValid && errors.push("El archivo no debe sobrepasar los 500 MB");          
      return errors;
    },
  },
  methods: {
    ...mapActions("seguimientoOrden", ["getContrato"]),
    openModalPlazo() {
      this.showModalPlazo = true;
    },
    closeModalPlazo() {
      this.showModalPlazo = false;
      this.add_days = null;
      this.doc_plazo = null;
    },
    async saveNewPlazo() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.temporalAlert({
          message:
            "Para actualizar el plazo contractual, debe llenar los campos requeridos",
          show: true,
          type: "error",
        });
        return;
      }
      let formData = new FormData();
      formData.append("id_contrato", this.seguimientoContrato.contrato.id);
      formData.append(
        "plazo_anterior",
        this.seguimientoContrato.contrato.plazo_contractual
      );
      formData.append("nuevo_plazo", this.add_days);
      formData.append("document", this.doc_plazo);
      const response = await this.services.ContratoService.postNewPlazo(
        formData
      );
      if (response) {
        this.temporalAlert({
          message: "Plazo actualizado correctamente",
          show: true,
          type: "success",
        });
        await this.getContrato(this.$route.params.idContrato);
        this.closeModalPlazo();
      }
    },
  },
};
</script>
