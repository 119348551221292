<template>
  <section class="my-2">
    <v-btn
      color="red"
      dark
      class="mb-4"
      @click="modalReporteSancion = true"
      v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
      >Reportar proveedor</v-btn
    >
    
    <data-table-component
      :headers="headers"
      :items="reporteSanciones"
      v-models:select="porPaginaModel"
      v-models:pagina="paginaModel"
      @paginar="paginar"
      :total_registros="filters?.total_rows"
    >
      <template #[`item.created_at`]="{ item }">
        {{ moment(item.created_at).format("DD/MM/YYYY") }}
      </template>
      <template #[`item.estado_sancion.nombre`]="{ item }">
        <v-chip
          label
          :color="
            item.estado_sancion.nombre === 'Pendiente'
              ? '#9E9E9E'
              : item.estado_sancion.nombre === 'Aprobado'
              ? 'success'
              : '#F44336'
          "
          >{{ item.estado_sancion.nombre }}</v-chip
        >
      </template>
      <template #[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="!haveRole('ROLE_OFICIAL_CUMPLIMIENTO')"
              v-bind="attrs"
              v-on="on"
              icon
              @click="ver(item)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver detalle</span>
        </v-tooltip>
        <!-- <v-tooltip
          v-if="
            item.estado_sancion.nombre === 'Pendiente' &&
            !haveRole('ROLE_OFICIAL_CUMPLIMIENTO')
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon @click="abrirEliminar(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar reporte</span>
        </v-tooltip> -->
      </template>
    </data-table-component>

    <v-dialog
      :max-width="900"
      v-model="modalReporteSancion"
      @click:outside="close()"
    >
      <v-card class="py-3" style="overflow: hidden">
        <v-container class="pt-0">
          <v-card-title class="text-h5 secondary--text">
            Reporte de proveedor
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <p class="font-weight-medium primary--text mb-0">
                  Nombre del proveedor:
                </p>
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  {{ providerData.nombre }}
                </p>
                <p class="font-weight-medium primary--text mb-0">
                  Nombre comercial:
                </p>
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  {{ providerData.nombre_comercial }}
                </p>
                <v-img
                  v-if="providerLogo"
                  max-height="242"
                  max-width="242"
                  :src="providerLogo"
                />
                <v-icon v-else color="primary" size="232px">
                  mdi-image-outline
                </v-icon>
              </v-col>
              <v-col cols="12" sm="12" md="9">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-textarea
                        label="Motivo de reporte *"
                        outlined
                        v-model="motivo"
                        @blur="$v.motivo.$touch()"
                        :error-messages="errorMotivoMessage"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="8">
                      <v-file-input
                        label="Informe de incumplimiento *"
                        outlined
                        prepend-icon=""
                        accept=".pdf"
                        append-icon="mdi-paperclip"
                        v-model="bin_documento_var"
                        @blur="$v.bin_documento_var.$touch()"
                        :error-messages="errorDocumentoMessage"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row class="mx-1">
              <v-btn outlined color="secondary" class="mr-4" @click="close()">
                Cancelar
              </v-btn>
              <v-btn color="red" dark @click="reportarProveedor()">
                Reportar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog :max-width="900" v-model="modalVer" @click:outside="close()">
      <v-card class="py-3" style="overflow: hidden">
        <v-container class="pt-0">
          <v-card-title class="text-h5 secondary--text"
            >Reporte de proveedor</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="3">
                <p class="font-weight-medium primary--text mb-0">
                  Nombre del proveedor:
                </p>
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  {{ providerData.nombre }}
                </p>
                <p class="font-weight-medium primary--text mb-0">
                  Nombre comercial:
                </p>
                <p class="text-subtitle-1 font-weight-bold primary--text">
                  {{ providerData.nombre_comercial }}
                </p>
                <v-img
                  v-if="providerLogo"
                  max-height="242"
                  max-width="242"
                  :src="providerLogo"
                />
                <v-icon v-else color="primary" size="232px">
                  mdi-image-outline
                </v-icon>
              </v-col>
              <v-col cols="12" sm="12" md="9">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-textarea
                        label="Motivo de reporte *"
                        outlined
                        v-model="motivo"
                        readonly
                        @blur="$v.motivo.$touch()"
                        :error-messages="errorMotivoMessage"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        text
                        class="text-no-style secondary--text"
                        @click="DownloadFileFtn"
                      >
                        Descargar documento adjunto
                        <v-icon class="ml-1"> mdi-download </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row class="mx-1">
              <v-btn
                outlined
                color="secondary"
                class="mr-4"
                @click="modalVer = false"
              >
                Regresar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <confirmation-dialog-component
      :show="modalEliminar"
      title="¿Está seguro de eliminar este reporte?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEliminar = false"
      @confirm="eliminarReporte()"
    ></confirmation-dialog-component>
  </section>
</template>
  
  <script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import { mapActions, mapState } from "vuex";
import ConfirmationDialogComponent from "../../../../components/ConfirmationDialogComponent.vue";
import DataTableComponent from "../../../../components/DataTableComponent.vue";

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  components: { DataTableComponent, ConfirmationDialogComponent },
  name: "ReporteSancionComponent",
  data: () => ({
    modalReporteSancion: false,
    modalEliminar: false,
    modalVer: false,
    reporteSanciones: [],
    headers: [
      {
        text: "Código",
        value: "codigo",
      },
      {
        text: "Origen",
        value: "origen",
      },
      {
        text: "Fecha de creación",
        value: "created_at",
      },
      {
        text: "Estado de reporte",
        value: "estado_sancion.nombre",
        align: "center",
      },
      {
        text: "Motivo de reporte",
        value: "motivo",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
    motivo: null,
    providerData: {},
    idReporte: null,
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      pagination: "true",
    },
    idContrato: null,

    ruta_documento: null,
    bin_documento_var: null,
  }),
  validations: {
    motivo: {
      required,
    },
    bin_documento_var: {
      required,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    ...mapState("agregarInsumo", ["selectedProvider"]),
    ...mapState("proveedores", ["providerLogo"]),
    ...mapState("seguimientoOrden", ["seguimientoContrato"]),
    errorMotivoMessage() {
      const errors = [];
      if (!this.$v.motivo.$dirty) return errors;
      !this.$v.motivo.required &&
        errors.push("El motivo de reporte es obligatorio");
      return errors;
    },
    errorDocumentoMessage() {
      const errors = [];
      if (!this.$v.bin_documento_var.$dirty) return errors;
      !this.$v.bin_documento_var.fileNameValid &&
        errors.push("El archivo debe ser un PDF");
      !this.$v.bin_documento_var.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      !this.$v.bin_documento_var.required &&
        errors.push("Debe de subir un documento");
      return errors;
    },
    porPaginaModel: {
      get() {
        return this.filters.per_page;
      },
      set(value) {
        this.setFiltroPorPagina(value);
      },
    },
    paginaModel: {
      get() {
        return this.filters.page;
      },
      set(value) {
        this.setFiltroPage(value);
      },
    },
  },
  methods: {
    ...mapActions("proveedores", ["getProviderLogo"]),
    async fetchProviderData() {
      let response = await this.services.AgreementMarco.getProviderAgreement(
        this.selectedProvider
      );
      if (response?.status == 200) {
        this.providerData = response?.data;
      }
    },
    async listarReportesSanciones() {

      let params = {
        ...this.filters,
        id_proveedor: this.selectedProvider
      }

      const lista = await this.services.Sanciones.getReporteSancion(
        Number(this.$route.params.idContrato),
        params
      ).catch(() => {

      });
      this.reporteSanciones = lista.data;
      this.filters.page = Number(lista.headers.page);
      this.filters.per_page = Number(lista.headers.per_page);
      this.filters.total_rows = Number(lista.headers.total_rows);

    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.listarReportesSanciones();
    },
    async reportarProveedor() {
      
        this.$v.$touch();
        if (this.$v.$invalid) return;
        const formData = new FormData();
        formData.append("id_proveedor", this.providerData.id);
        formData.append("origen", this.seguimientoContrato?.contrato.numero);
        formData.append("motivo", this.motivo);
        formData.append("id_contrato_orden", Number(this.$route.params.idContrato))
        if (this.bin_documento_var !== null) {
          formData.append("documento", this.bin_documento_var);
        }

        const { status } = await this.services.Sanciones.postReporteSancion(
          formData
        ).catch(() => {
          if(this.motivo === null || this.bin_documento_var === null){
            this.temporalAlert({
              show:true,
              type:"error",
              message:"Debe de ingresar los datos requeridos"
            })
          }

        });

          this.motivo = null;
          this.bin_documento_var = null;
          this.$v.$reset();
          this.close();
          this.listarReportesSanciones();
    },
    close() {
      this.$v.$reset();
      this.bin_documento_var = null;
      this.motivo = null;
      this.modalReporteSancion = false;
    },
    abrirEliminar(item) {
      this.idReporte = item.id;
      this.modalEliminar = true;
    },
    async eliminarReporte() {

      const { status } = await this.services.Sanciones.deleteReporteSancion(
        this.idReporte
      ).catch(() => {

      });

      if (status === 204) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: "Reporte de sanción eliminado",
        });
        this.modalEliminar = false;
      }

      this.listarReportesSanciones();
    },
    ver(item) {
      this.modalVer = true;
      this.motivo = item.motivo;
      this.ruta_documento = item.documento;
    },

    async DownloadFileFtn() {

      const { status, data, headers } =
        await this.services.PacProcesos.descargarDocumentoProceso({
          ruta: this.ruta_documento,
          disk: "local",
        });

      if (status === 200) {
        const blob = new Blob([data], {
          type: headers["content-type"],
        });

        const file = new File([blob], "reporte_proveedor.pdf", {
          type: headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = file.name;
        link.click();
      }

    },
  },

  watch: {
    modalVer(value) {
      if (!value) {
        this.motivo = null;
        this.ruta_documento = null;
      }
    },
  },

  async created() {
    await this.fetchProviderData();
    await this.getProviderLogo(this.selectedProvider);
    await this.listarReportesSanciones();
  },
};
</script>